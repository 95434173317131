.Demo-btn {
    background: linear-gradient(90deg, #ffffff,#ffffff); /* Use 'background' for gradients */
    box-shadow: inset 0 4px 8px #FFFFFF29, /* Inner shadow */
                0 4px 8px #9C39FF40;       /* Outer shadow */
    border: none; /* Optional: remove default button border */
    padding: 10px 20px; /* Optional: adjust padding for button */
    border-radius: 8px; /* Optional: rounded corners */
    cursor: pointer; /* Show pointer on hover */
    transition: box-shadow 0.3s ease; /* Optional: smooth transition */
}

