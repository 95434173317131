.bg-linear-gradient{
    background: linear-gradient(90.6deg, #6B41FF 27%, #F64CFF 68%, #FFC553 100%);
    border-radius: 241.5px; 
    filter: blur(50px); 
    width: 290px;

}

.contact-tag-btn{
    text-align: center;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 16px;
    padding-right: 16px;
    color: #0E051B;
    background: linear-gradient(white, white) padding-box,
        linear-gradient(90deg, #6B41FF,#F64CFF,#FFC553) border-box;
    
    border-radius: 8px;
    border: 1px solid transparent;
}