.icon-container{
    text-align: center;
    
    font-weight: 400;
    color: #0E051B;
    font-size: 14px;
    background: linear-gradient(101.84deg, #5C24FC 2.78%, #9D7AFF 98.95%);
    border-radius: 8px;
    width: 80px;
    height: 80px;
}