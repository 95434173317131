.bg-linear-gradient {
    background: linear-gradient(383.6deg, #6B41FF 27%, #F64CFF 68%, #FFC553 100%);
    border-radius: 241.5px; 
    filter: blur(50px); 
    width: 290px;
}


.bottom-linear-gradient {
    background: linear-gradient(to bottom, rgba(198, 193, 193, 0) 0%, rgba(255, 255, 255, 0.922) 100%);
}
