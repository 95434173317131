.text-linear-gradient {

    background: linear-gradient(90deg, #6B41FF, #F64CFF, #FFC553);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}


.text-linear-gradient2 {

    background: linear-gradient(90deg, #F64CFF, #FFC553 #6B41FF);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}


